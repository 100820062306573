import React from "react";
import { useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import ReplyIcon from '@mui/icons-material/Reply';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import Checkbox from '@mui/material/Checkbox';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';


const AppBase = () => {
    const ref = useRef(null);
    const [content, setContent] = useState('');
    const [contentSubject, setContentSubject] = useState('');
    const [selectedFiles, SetAttachments] = useState([]);
    const [rawAttachments, SetRawAttachments] = useState([]);
    const [forwardSelect, setForwardSelect] = useState(false);
    const [replySelect, setReplySelect] = useState(false);
    const [viewOnly, setViewOnly] = useState(false);
    const [downloadOnly, setDownloadOnly] = useState(false);

    let navigate = useNavigate();


    const handleClick = () => {
        ref.current?.click();
    };

    const handleChangeinputAttachments = (e) => {
        const files = e.target.files;
        // let obj = {
        //     name: files[0]['name'],
        //     size: files[0]['size'],
        //     type: files[0]['type']
        // }
        // SetRawAttachments(rawAttachments => [...rawAttachments, files]);
        const filesArray = Array.from(files);
        SetRawAttachments(prevFiles => [...prevFiles, ...filesArray]);
        SetAttachments(prevFiles => [...prevFiles, ...filesArray]);
        console.log("setSelectedFiles", rawAttachments)
    };

    React.useEffect(() => {
        console.log('rawAttachments', rawAttachments);
    }, [selectedFiles, rawAttachments]);

    const deleteAttachment = (name) => {
        const newArray = selectedFiles.filter(obj => {
            return obj.name !== name;
        })
        SetAttachments(newArray);
        SetRawAttachments(newArray);
        // SetAttachments(selectedFiles.splice(index, index))
    }

    const handleChange = () => {
        var text = document.getElementById('input').innerHTML;
        // console.log("value:", content);
        // return
        console.log("state:::::", rawAttachments)
        navigate('/OTP', {
            state: {
                rawMessageEntered: text,
                subject: contentSubject,
                forwardable: forwardSelect,
                replyAll: replySelect,
                attachments: rawAttachments,
                expiry: value,
                downloadOnly: downloadOnly,
                viewOnly: viewOnly
            }
        });
    }
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [value, setValue] = React.useState('7');

    const handleChangeRadio = (event) => {
        setValue(event.target.value);
    };
    const enabled =
        contentSubject.length > 0 &&
        document.getElementById('input').innerHTML.length > 0;

    return (
        <div>
            <div className="flex justify-center mt-8">
                <label className="text-black font-bold ml-5">
                    Enter message to be sent
                </label>
            </div>
            <div style={{ justifyContent: "center", display: 'flex', marginLeft: '0%', marginTop: '3%', marginBottom: '-2.5%', fontFamily: "Lato !important" }}>
                <TextField placeholder="Subject" sx={{
                    fontFamily: 'Lato !important',
                    width: '80%',
                    input: {
                        color: "black",
                        fontFamily: "Lato !important"
                    },
                    label: {
                        color: 'black !important',
                        fontWeight: 'bolder',
                        fontFamily: "Lato !important"
                    },
                    fieldset: {
                        borderColor: "#e5e7eb !important",
                        borderBottom: 'none !important'
                    }
                }} focused onChange={e => setContentSubject(e.target.value)} />
            </div>
            <div className="flex justify-center mt-3">
                <div className="relative rounded border-2 w-[80%] text-black h-[40rem] mt-8 p-4 place-content-center">
                    <div contentEditable="true" id='input' className="h-[28rem] overflow-y-auto outline-0 font-normal" style={{ whiteSpace: 'pre-wrap !important' }}
                        type="input" value={content} onInput={e => setContent(e.currentTarget.innerHTML)}>
                        {/* <textarea name="content" style="display:none;whiteSpace: 'pre-wrap';" /> */}
                    </div>



                    <div className="justify-center absolute inline-block left-0	bottom-[12px] pl-[33px]">
                        {selectedFiles.map((data, index) => {
                            return (
                                <div key={index} className="box-border h-10 w-96 border-2 p-2 border-current ">
                                    <span className="text-blue-600"> {data.name}</span>
                                    <ClearIcon className="text-black absolute right-[3%] cursor-pointer" onClick={() => deleteAttachment(data.name)} />
                                </div>
                            )
                        })}
                    </div>


                    <div class="flex justify-end mt-[5%]">

                        {rawAttachments.length !== 0 ? (
                            <div className="grid grid-cols-2 gap-2 ">
                                <div class="space-y-4 text-center">
                                    <div class="w-[7rem] h-16">
                                        <Checkbox
                                            title="Allow view only"
                                            icon={<VisibilityOutlinedIcon className=" text-black flex mt-[9px]" />}
                                            checkedIcon={<VisibilityOutlinedIcon color="blue" />}
                                            onChange={() => setViewOnly(true)}
                                        />
                                    </div>
                                    <h1 class=" px-2   py-0.5  text-sm">View only</h1>
                                </div>
                                <div class="space-y-4 text-center">
                                    <div class="w-[7rem] h-16">
                                        <Checkbox
                                            title="Allow download only"
                                            icon={<FileDownloadOutlinedIcon className=" text-black flex mt-[9px] ml-[1rem]" />}
                                            checkedIcon={<FileDownloadOutlinedIcon color="blue" />}
                                            onChange={() => setDownloadOnly(true)}
                                        />
                                    </div>
                                    <h1 class=" px-2   py-0.5  text-sm">Download</h1>
                                </div>
                            </div>


                        ) : ''}

                        <div class="space-y-4 text-center">
                            <div class="w-[7rem] h-16">
                                <div>
                                    <Tooltip title="Msg Expiry">
                                        <IconButton
                                            onClick={handleClickMenu}
                                            size="small"
                                            sx={{ mb: 2 }}
                                            aria-controls={open ? 'expiry-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                        >
                                            <Avatar sx={{ width: 32, height: 32, marginTop: '10px' }}>E</Avatar>
                                        </IconButton>
                                    </Tooltip>

                                    <Menu
                                        anchorEl={anchorEl}
                                        id="expiry-menu"
                                        open={open}
                                        onClose={handleClose}
                                        onClick={handleClose}
                                    >
                                        <MenuItem onClick={handleClose}>
                                            <FormControl component="fieldset">
                                                <RadioGroup aria-label="top" value={value} onChange={handleChangeRadio}>
                                                    <FormControlLabel value="0" control={<Radio />} label="immediate" />
                                                    <FormControlLabel value="1" control={<Radio />} label="1 day" />
                                                    <FormControlLabel value="7" control={<Radio />} label="7 days" />
                                                    <FormControlLabel value="30" control={<Radio />} label="30 days" />
                                                </RadioGroup>
                                            </FormControl>
                                        </MenuItem>
                                    </Menu>
                                    {/* </Box> */}
                                </div>
                            </div>
                            <h1 class=" px-2  py-0.5 text-sm">Expiry</h1>
                        </div>
                        <div class="space-y-4 text-center">
                            <div class="w-[7rem] h-16">
                                <div onClick={handleClick} className="text-black cursor-pointer" title="Attachments">
                                    <InsertLinkIcon className="w-6 h-6 mt-[17%]" />
                                    <input type="file" ref={ref} className="hidden" multiple onChange={handleChangeinputAttachments} />
                                </div>
                            </div>
                            <h1 class=" px-2   py-0.5  text-sm">Attachments</h1>
                        </div>
                        <div class="space-y-4 text-center">
                            <div class="w-[7rem] h-16 ">
                                <Checkbox
                                    title="Allow reply"
                                    icon={<ReplyIcon className="text-red-600 flex mt-[9px]" />}
                                    checkedIcon={<ReplyIcon color="green" className=" text-green-600" />}
                                    onChange={() => setReplySelect(true)}
                                />
                            </div>
                            <h1 class=" px-2   py-0.5  text-sm">Reply</h1>
                        </div>
                        <div class="space-y-4 text-center">
                            <div class="w-[7rem] h-16">
                                <Checkbox
                                    title="Allow forward"
                                    icon={<ShortcutIcon className="text-red-600 flex mt-[9px]" />}
                                    checkedIcon={<ShortcutIcon color="green" className=" text-green-600" />}
                                    onChange={() => setForwardSelect(true)}
                                />
                            </div>
                            <h1 class=" px-2   py-0.5  text-sm">Forward</h1>
                        </div>


                    </div>


                </div>

            </div>
            <div className="flex justify-center">
                <button className={`w-24 text-center mt-6 rounded  border-2   p-2 
                              ${!enabled ? "cursor-auto bg-[#a0a0a0] border-[#a0a0a0] text-white" : "cursor-pointer bg-[#31a0f633] border-[#31a0f640] text-black hover:rounded hover:border-[#31a0f633] hover:bg-[#6366f1] hover:text-white"}
                              `}
                    onClick={handleChange}
                    title={`${!enabled ? "Please compose the message to be sent" : ""}`}
                    disabled={!enabled}>
                    Next
                </button>
            </div>
        </div>

    );
}

export default AppBase;

