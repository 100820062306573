import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { deepOrange, deepPurple } from '@mui/material/colors';
import Chip from '@mui/material/Chip';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useLocation, useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';
import fileDownload from 'js-file-download'
import TextField from '@mui/material/TextField';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import ReplyIcon from '@mui/icons-material/Reply';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import Checkbox from '@mui/material/Checkbox';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import parse from 'html-react-parser'
import { getSentMessage, getDocuments } from '../services/ApiService'
import QRCode from 'react-qr-code';
import html2canvas from 'html2canvas';
import FileViewer from './FileViewer';

const ReadSendMeaasge = () => {
    const location = useLocation();
    const [getAttachments, setgetAttachments] = useState([]);
    const [data1, setdata1] = useState('');
    const [respData, setRespData] = useState({});
    const [mainuuid, setMainuuid] = useState('');
    const [back, setBack] = useState('#FFFFFF');
    const [fore, setFore] = useState('#000000');
    const [size, setSize] = useState(100);
    const containerRef = useRef(null);

    const [documentData, setDocumentData] = useState(null);
    const [fileType, setFileType] = useState(null);
    const [showOtherComponent, setShowOtherComponent] = useState(false);

    useEffect(() => {
        GetData();
    }, []);

    const GetData = () => {
        console.log("location.state.data", location.state.data)
        let id = '';
        if (location.state.data.repliedUuid === null) {
            id = ''
        }
        else {
            id = location.state.data.repliedUuid
        }
        let localtoken = localStorage.getItem('token');
        let token = JSON.parse(localtoken).token;
        let obj = {
            uuid: location.state.data.uuid,
            mainUuid: id,
            messageType: location.state.data.messageType,
        }
        console.log("obj", obj)
        getSentMessage(token, obj).then((response) => {
            console.log("getSentMessage", response.data.message)
            if (response.data.status === 'success') {
                setRespData(response.data.message)
                console.log("resp", respData)
                console.log("respData.secretKey", respData.secretKey, typeof respData.secretKey)
                const first6 = response.data.message.secretKey.slice(0, 6);
                const remaining = response.data.message.secretKey.substring(6);
                const reversOPT = first6.split("").reverse().join(""); //OTP
                const reversPattern = remaining.split("").reverse().join(""); //Pattern
                const finalKey = reversPattern + reversOPT + '00'; //Key
                console.log("finalKey", finalKey)
                const decrypted = CryptoJS.AES.decrypt(response.data.message.encryptedMessage, finalKey).toString(CryptoJS.enc.Utf8);
                console.log("222", decrypted)
                setdata1(JSON.parse(decrypted))
                console.log("data1", data1);
                setgetAttachments(response.data.message.attachments);
                console.log("getAttachments", Array.isArray(getAttachments))
            }
        })
    }

    const documents = (fileId, filename, message) => {
        console.log("location.state.downloadOnly", message)
        let localtoken = localStorage.getItem('token');
        let token = JSON.parse(localtoken).token;

        let obj = {
            uuid: message.uuid,
            mainUuid: message.repliedUuid | null,
            messageType: message.messageType,
            fileId: fileId,
        }
        getDocuments(token, obj).then((response) => {
            console.log("fileType:::", response.data['attachments'])
            // console.log("response.data.attachments[0]", response.data['attachments'].filename)
            if (response.data.status === 'success') {
                setDocumentData(response.data['attachments'].data)
                setFileType(response.data['attachments'].fileType);
                const linkSource = `data:${response.data['attachments'].fileType};base64,${response.data['attachments'].data}`;
                console.log("fileType:::", response.data['attachments'].fileType)

                if (respData.downloadOnly === true) {
                    const downloadLink = document.createElement("a");
                    downloadLink.href = linkSource;
                    downloadLink.download = filename;
                    downloadLink.click();
                }
                if (respData.viewOnly === true) {
                    setShowOtherComponent(!showOtherComponent);
                    // console.log("viewOnly")
                    // if (response.data['attachments'].fileType === 'image/png') {
                    //     console.log("image")
                    //     var image = new Image();
                    //     image.src = `data:${response.data['attachments'].fileType};base64,` + response.data['attachments'].data;

                    //     var w = window.open("", '', "toolbars=no,scrollbars=no,status=no,resizable=no");
                    //     w.document.write("<html style={height:20px;width:20px;}<head><title>" + filename + "</title><style>body{margin: 0px;}</style></head>");
                    //     w.document.write(image.outerHTML);
                    // }
                    // else {
                    //     let pdfWindow = window.open("");
                    //     pdfWindow.document.write("<html<head><title>" + filename + "</title><style>body{margin: 0px;}</style></head>");
                    //     pdfWindow.document.write(`<body><embed width='100%' height='100%' src=data:${response.data['attachments'].fileType};base64, " + encodeURI(response.data['attachments'].data) + "#toolbar=0&navpanes=0&scrollbar=0></embed></body></html>`);
                    //     pdfWindow.document.close();
                    // }
                }
            }
        })
    }


    const handleQrDownload = () => {
        const container = containerRef.current;

        if (container) {
            // Make the hidden content visible for the screenshot
            const hiddenContent = container.querySelectorAll('.hidden-content');
            hiddenContent.forEach(element => {
                element.style.visibility = 'visible';
            });
            html2canvas(container).then(canvas => {
                const context = canvas.getContext('2d');

                // Convert canvas to data URL
                const dataUrl = canvas.toDataURL('image/png');

                // Reset the visibility of hidden content
                hiddenContent.forEach(element => {
                    element.style.visibility = 'hidden';
                });

                // Create a temporary link element
                const link = document.createElement('a');
                link.href = dataUrl;
                link.download = 'qrcode' + '.png';

                // Simulate a click to trigger the download
                link.click();
            });
        }
    };

    const handleCloseViewer = () => {
        setShowOtherComponent(!showOtherComponent);
    };

    return (
        showOtherComponent ?
            <div>
                <button className="float-right text-red-500 text-lg font-extrabold pr-5" title="close Viewer" onClick={handleCloseViewer}>X</button>
                <FileViewer base64Data={documentData} fileType={fileType} />
            </div>
            :
            <div className='w-full' style={{ userSelect: "none" }}>
                <div>
                    <h2 class="text-3xl font-normal mb-5 mt-2" style={{ padding: "30px 0 8px 95px" }}>
                        {respData.subject}
                    </h2>

                    <div class="text-black border-grey-600 m-9">
                        <div>

                            <div>
                                <div class="mt-8">
                                    <div className='flex justify-between'>
                                        <Avatar className="uppercase" style={{ backgroundColor: "#081a51d6" }}>{respData.sender && respData.sender.charAt(0)}</Avatar>
                                        <div class="mr-[61%]">
                                            {respData.sender}
                                            <br />
                                            <span class="text-xs text-slate-400">
                                                to {respData.receiver && respData.receiver.join()}
                                            </span>
                                        </div>
                                        <div class="justify-end items-center mr-[10%] whitespace-nowrap	">
                                            <span class="text-xs text-slate-400 mt-5 flex" >
                                                {respData.sentTime}
                                                <span ref={containerRef}>
                                                    <QRCode className='mt-[-100%] ml-[70%] pointer'
                                                        title="QR"
                                                        value={respData.uuid + " " + respData.repliedUuid + " " + respData.messageType}
                                                        bgColor={back}
                                                        fgColor={fore}
                                                        size={size === '' ? 0 : size}
                                                        onClick={handleQrDownload}
                                                        style={{ cursor: 'pointer' }}
                                                    />

                                                    <div className="hidden-content text-[5px]" style={{ visibility: 'hidden', padding: '5%' }}>
                                                        Subject : {respData.subject}
                                                        <div className='p-[5%] text-red-500'>
                                                            Scan this on Resiliunt
                                                        </div>
                                                    </div>
                                                </span>
                                            </span>
                                            <span>

                                            </span>
                                        </div>
                                    </div>
                                    <div style={{ whiteSpace: 'pre-wrap', marginLeft: '10px', marginLeft: '4.5%', marginTop: "2%", fontWeight: "normal" }}>
                                        <div >
                                            {parse(data1)}
                                        </div>


                                    </div>
                                    <div className='flex'>
                                        {Array.isArray(getAttachments)}
                                        {
                                            getAttachments.length !== 0 ? (
                                                getAttachments.map((file, index) => {
                                                    return (
                                                        <div className="border border-[#e5e5e5] h-[120px] w-[180px] ml-[55px] mb-[3px] mt-[80px] cursor-pointer"
                                                            onClick={() => documents(file.fileId, file.filename, respData)}>
                                                            <div className='relative h-[85px] items-center flex justify-center'>
                                                                <img src="https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_image_x16.png" className="w-[25px] h-[25px] text-center" />
                                                            </div>
                                                            <div className='border-t-[1.5px] border-[#e5e5e5] bg-[#f5f5f5] text-[12px] h-[33px] flex items-center whitespace-nowrap overflow-hidden'>
                                                                <span className='truncate ...'>{file.filename}</span>
                                                            </div>
                                                        </div>
                                                    )

                                                })
                                            ) : ""

                                        }
                                    </div>
                                    <div class="mt-10 ml-14 mb-4">

                                    </div>
                                </div>
                            </div>
                        </div >
                    </div >
                </div>
            </div>
    )
}


export default ReadSendMeaasge;