import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Checkbox from '@mui/material/Checkbox';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarRateIcon from '@mui/icons-material/StarRate';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PatternIcon from '@mui/icons-material/Pattern';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import { listOfMessages, getSecretePattern, deleteMessage, checkPatternValidity } from '../../services/ApiService'
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from "react-router-dom";
import Pagination from '../Pagination';
import Swal from "sweetalert2";
import Avatar from '@mui/material/Avatar';

import { ToastContainer, toast, Zoom, Flip, Slide } from "react-toastify";

const Inbox = () => {
    let navigate = useNavigate();

    const [checkedstate, setChecked] = React.useState(false);
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [inboxMessagesPerPage] = useState(13);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    useEffect(() => {
        GetData();
        checkpatternvalidity();
        console.log(data);
    }, []);

    const checkpatternvalidity = () => {

        let localtoken = localStorage.getItem('token');
        let token = JSON.parse(localtoken).token;
        checkPatternValidity(token).then(response => {
            if (response.data.status === 'error') {
                Swal.fire({
                    icon: 'error',
                    text: response.data.message,
                    confirmButtonText: "Reset",
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate('/setSecretcomp');

                    }
                })
            }
        }).catch((err) => {
            console.log("bottom-center", err)
            toast.error("Session Expired Logout and Login again", {
                position: toast.POSITION.BOTTOM_CENTER,
                className: "toast-message",
                hideProgressBar: true,
                theme: "colored",
                closeOnClick: true,
                // transition: "Zoom"
            });
        })
    }

    const GetData = () => {
        let localtoken = localStorage.getItem('token');
        let token = JSON.parse(localtoken).token
        console.log(token);
        listOfMessages(token).then(response => {
            console.log(response.data.messages);
            if (response.data.status !== 'no messages found') {
                // let finalArray = [];
                // for (let data of response.data.messages) {
                //     if (data['repliedMessages'].length === 0) {
                //         data['mainMessage'].flag = 'mainMessage'
                //         finalArray.push(data['mainMessage'])
                //     }
                //     else if (data['repliedMessages'].length !== 0) {
                //         data['repliedMessages'][0].flag = 'repliedMessages'
                //         finalArray.push(data['repliedMessages'][0])
                //     }
                // }
                // console.log("finalArray", finalArray)
                // // return
                setData(response.data.messages);

            }
            else if (response.data.status === 'no messages found') {
                setData([])
            }
        }).catch((err) => {
            toast.error("Session expired Logout and Login again", {
                position: toast.POSITION.BOTTOM_CENTER,
                className: "toast-message",
                hideProgressBar: true,
                theme: "colored",
                closeOnClick: true,
                // transition: "Zoom"
            });
        })
    }

    const indexOfLastMessage = currentPage * inboxMessagesPerPage;
    const indexOfFirstMessage = indexOfLastMessage - inboxMessagesPerPage;
    const currentMessage = data.slice(indexOfFirstMessage, indexOfLastMessage)
    console.log("data", currentMessage)
    const paginate = pageNumber => setCurrentPage(pageNumber);

    const paginateFront = () => {
        if (currentPage !== Math.ceil(data.length / inboxMessagesPerPage)) {
            setCurrentPage(currentPage + 1)
        }
    };
    const paginateBack = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    };

    const setSelectedRow = (uuid, subject, sender, getSecretePattern, fulldata) => {
        if (getSecretePattern === true) {
            navigate('/MessageBody', {
                state: {
                    requestForSecretPattern: true,
                    decryptedOTP: '',
                    routingFrom: '',
                    encryptedMessage: fulldata.body,
                    patternCode: '',
                    subject: subject,
                    mailfrom: sender,
                    uuid: uuid
                }
            });
        } else {
            let forward = Boolean(fulldata.forward);
            let reply = Boolean(fulldata.replyAll);
            console.log("location.state.forwardable", typeof forward, forward)
            navigate('/reciverPage', {
                state: {
                    fullData: fulldata,
                    uuid: uuid,
                    subject: subject,
                    mailfrom: sender,
                    forwardable: forward,
                    replyAll: reply
                }
            });
        }

    }

    const requestForSecretPattern = (uuid, userName) => {
        let localtoken = localStorage.getItem('token');
        let token = JSON.parse(localtoken).token;
        let obj = {
            uuid: uuid,
            userName: userName
        }
        getSecretePattern(token, obj).then(response => {
            console.log(response.data.messages);
            if (response.data.status === 'Pattern requested successfully') {
                Swal.fire({
                    icon: 'success',
                    title: '',
                    text: response.data.status,
                    confirmButtonText: "OK",
                }).then((result) => {
                    if (result.isConfirmed) {
                        GetData()
                    }
                })
            }
        })
    }

    const deleteMessageAPIcall = (data, userName) => {
        console.log("data", data)
        let localtoken = localStorage.getItem('token');
        let token = JSON.parse(localtoken).token;
        if (data.repliedUuid === null) {
            console.log("message.repliedUuid", data.repliedUuid)
            data.repliedUuid = ''
        }
        let obj = {
            uuid: [data.uuid],
            userName: userName,
            mainUuid: data.repliedUuid,
            messageType: data.messageType
        }
        deleteMessage(token, obj).then(response => {
            console.log(response.data.messages);
            GetData()
        })
    }

    return (
        <div>
            <div className='card p-5 m-3 w-[99%] shadow-2xl rounded-lg  border border-grey-600 hover:shadow-lg'>
                <h2 className='text-lg font-bold'>
                    Inbox Messages
                    <Pagination inboxMessagesPerPage={inboxMessagesPerPage} totalMessages={data.length} paginateBack={paginateBack}
                        paginateFront={paginateFront}
                        currentPage={currentPage} />
                </h2>
                <div className="grid grid-cols-1 divide-y divide-slate-400/25 text-lg font-thin	m-3 bg-[#f2f6fc]">

                    {currentMessage.length !== 0 ?
                        currentMessage.map((data, index) => (
                            <div className='p-2' key={index}>
                                <Checkbox
                                    checked={checkedstate}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }} color="default" />
                                {/* <Checkbox
                                checked={checkedstate}
                                label="Label"
                                inputProps={{ 'aria-label': 'controlled' }} color="default"
                                icon={<StarBorderIcon />}
                                checkedIcon={<StarRateIcon />}
                            /> */}
                                <span onClick={() => setSelectedRow(data.uuid, data.subject, data.sender, data.getSecretePattern, data)} className="cursor-pointer">
                                    <span className='inline-flex w-[9ch] overflow-hidden'>
                                        <p className='truncate ...' title={`${data.sender}`}>{data.sender}</p>
                                    </span>
                                    <span className={`ml-16 whitespace-nowrap inline-flex  md:w-[80ch] lg:w-[114ch] overflow-hidden ${data.readStatus === 'unseen' ? "font-semibold" : ""}`}>
                                        {data.subject}
                                        <span className='ml-2 mr-2' >-</span>
                                        <p className={`truncate ... ${data.readStatus === 'unseen' ? "font-semibold" : ""}`}>
                                            {data.encryptedMessage}
                                        </p>
                                    </span>
                                </span>
                                <div className='float-right flex'>
                                    <span className='text-[#5f6368] text-[15px]'>
                                        {new Date(data.sentTime).toLocaleString('default', { month: 'short' })} {new Date(data.sentTime).getDate()}
                                    </span>
                                    <span className='ml-4 cursor-pointer'>
                                        <Tooltip title="Delete Message">
                                            <DeleteOutlineIcon className='mt-[-0.3rem]'
                                                onClick={() => { deleteMessageAPIcall(data, localStorage.getItem('userid')) }} />
                                        </Tooltip>
                                    </span>
                                    <span className='ml-4 cursor-pointer'>
                                        <Tooltip title={"Expires on" + ' : ' + data.expiresIn}>
                                            <Avatar sx={{ width: 32, height: 32, marginTop: '0px', color: data.expiryDays > 1 ? "#4CAF50" : "#F44336", backgroundColor: 'white', fontWeight: 'bold' }} className={`mt-[-0.3rem]`}>E</Avatar>
                                        </Tooltip>
                                    </span>
                                </div>
                                {/* <span className='ml-4 cursor-pointer'>
                                <Tooltip title="Request for Secret Pattern">
                                    <PatternIcon
                                        onClick={() => { requestForSecretPattern(data.uuid, localStorage.getItem('userid')) }} />
                                </Tooltip>
                            </span> */}
                                <div className='ml-[12%]'>
                                    {
                                        data.attachments.length !== 0 ? (
                                            data.attachments.map((data, index) => (
                                                <div className='items-center rounded-full border border-[#64798f4d] max-w-[160px] min-w-[64px] h-[40px] ml-[1%] overflow-hidden whitespace-nowrap inline-flex'>
                                                    <span className='truncate ... text-[#5f6368] pl-3' title={data.filename}>
                                                        <img src="https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_image_x16.png" className='w-[16px] h-[16px] mr-[8px] inline' />
                                                        {data['filename']}
                                                    </span>
                                                </div>
                                            ))
                                        ) : ""
                                    }
                                </div>

                            </div>
                        ))
                        :
                        <div className='flex justify-center bg-white text-xl'>
                            No messages found
                        </div>
                    }
                </div >
            </div >
            <ToastContainer limit={2} transition={Slide} />
        </div>
    )
}

export default Inbox