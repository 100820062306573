import { useState, useEffect, useRef } from 'react';
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { generateEncryptSessionKey, findUsers } from "../../services/ApiService";
import { FormControl, Chip, makeStyles, Input } from "@material-ui/core";
import Swal from "sweetalert2";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ReactDOMServer from 'react-dom/server'; // Import ReactDOMServer
import QRCodeComponent from './QRCode'
function getDate() {
    const today = new Date();
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const year = today.getFullYear();
    const date = (today.getDate()).toString().padStart(2, "0");
    const time = today.getHours() + ':' + today.getMinutes();
    return `${date}-${month}-${year} ${time}`;
}


const SendTo = () => {
    // const classes = useStyles();
    let navigate = useNavigate();
    const location = useLocation();
    let localtoken = localStorage.getItem('token');
    let token = JSON.parse(localtoken).token
    const [values, setValues] = useState([]);
    const [currValue, setCurrValue] = useState("");
    const [error, setError] = useState("");
    const cc = [];
    const [currentDate, setCurrentDate] = useState(getDate());

    const submit = () => {
        let obj = {
            sender: localStorage.getItem('userid'),
            secretKey: location.state.secretKey,
            receiver: values,
            message: location.state.encryptedMessage,
            subject: location.state.subject,
            dateTime: currentDate,
            forwardable: location.state.forwardable,
            replyAll: location.state.replyAll,
            expiry: location.state.expiry,
            downloadOnly: location.state.downloadOnly,
            viewOnly: location.state.viewOnly,
            cc: cc
            // attachments: location.state.attachments
        }
        console.log("generateEncryptSessionKey", obj)
        generateEncryptSessionKey(token, obj, location.state.attachments).then(response => {
            console.log(response.data.data);

            navigate('/QRCodeComponent', {
                state: {
                    data: response.data.data,
                    subject: location.state.subject
                }
            });

            // Swal.fire({
            //     title: "QR Code",
            //     html: ReactDOMServer.renderToString(
            //         <QRCodeComponent
            //             respData={response.data.data}
            //             back={back}
            //             fore={fore}
            //             size={size}
            //         />
            //     ),
            //     confirmButtonText: "OK",
            // }).then((result) => {
            //     if (result.isConfirmed) {
            //         navigate('/Inbox');
            //     }
            // })

        })
    }
    const handleKeyUp = (e) => {
        if (e.keyCode == 13) {
            let obj1 = {
                users: [e.target.value]
            }
            findUsers(token, obj1).then(resp => {
                console.log("resp", resp.data['usersNotFound'].length)
                if (resp.data['usersNotFound'].length !== 0) {
                    setError("Please enter the valid user id")
                }
                else {
                    setValues((oldState) => [...oldState, e.target.value]);
                    console.log("values", values);
                    setCurrValue("");
                    setError("");
                }
            })
        }
    };
    const handleChange = (e) => {
        setCurrValue(e.target.value);
    };

    const handleDelete = (item, index) => {
        let arr = [...values]
        arr.splice(index, 1)
        console.log(item)
        setValues(arr)
    }

    return (
        <div className='w-full grid place-items-center justify-center mt-[9%]'>
            <div className="flex justify-center mt-8 mb-[5%]">
                <label className="text-black font-bold ml-5">
                    Send Message to
                </label>
            </div>
            <div className="text-center text-black mb-[5%]">
                {values.map((item, index) => (
                    <Chip size="big" className='m-1' onDelete={() => handleDelete(item, index)} label={item} />
                ))}
            </div>
            <div style={myComponentStyle.inputfield} className=" text-center text-black">
                {/* <FormControl classes={{ root: classes.formControlRoot }}> */}
                <input type={'text'} style={myComponentStyle.input}
                    // className={'inputfield'}
                    value={currValue}
                    onChange={handleChange}
                    onKeyDown={handleKeyUp}
                />
                {/* </FormControl> */}
            </div>

            {error ?
                <p className="text-red-600">{error}</p> : ""}
            <div className='mt-16'>
                <button style={myComponentStyle.submit} onClick={submit}>Submit</button>
            </div>
        </div>
    )
}

export default SendTo;


const myComponentStyle = {
    inputfield: {
        width: '100%',
        display: "flex",
        // justifyContent: ' space - around',
        // marginTop: '-47%'
    },
    input: {
        height: "3em",
        width: "30em",
        border: '2px solid #dad9df',
        outline: "none",
        textAlign: "center",
        fontSize: "1.5em",
        borderRadius: "0.3em",
        backgroundColor: "#ffffff",
        outline: "none"
    },
    submit: {
        backgroundColor: '#044ecf',
        border: "none",
        outline: "none",
        fontSize: "1.2em",
        padding: "0.8em 2em",
        color: "#ffffff",
        borderRadius: " 0.1em",
        // margin: "1em auto 0 auto",
        cursor: "pointer",
    }
}

// const useStyles = makeStyles((theme) => ({
//     formControlRoot: {
//         display: "flex",
//         alignItems: "center",
//         gap: "8px",
//         width: "600px",
//         // flexWrap: "wrap",
//         flexDirection: "row",
//         border: '2px solid lightgray',
//         padding: 4,
//         borderRadius: '4px',
//         "&> div.container": {
//             gap: "6px",
//             display: "flex",
//             flexDirection: "row",
//             flexWrap: "wrap"
//         },
//         "& > div.container > span": {
//             backgroundColor: "gray",
//             padding: "1px 3px",
//             borderRadius: "4px"
//         }
//     }
// }));
