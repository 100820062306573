import './Header.css'
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';
import RESILIUNTLogo from '../../assets/RESILIUNTLogo.png'

const Header = () => {
    let clickedValue;
    const navigate = useNavigate();
    let loggedName = localStorage.getItem('username')



    const handleChange = () => {
        console.log("clicked---initial", clickedValue)
        if (clickedValue === 'Reciver') {
            clickedValue = 'Sender';
        }
        else if (clickedValue === 'Sender' || clickedValue === undefined) {
            clickedValue = 'Reciver';
        }
        let value = clickedValue;
        console.log("clicked", value);
        navigateMethod(value)
    }

    const navigateMethod = (valueData) => {
        if (valueData === 'Reciver') {
            navigate('/reciverPage');
        }
        else if (valueData === 'Sender') {
            navigate('/');
        }
    }

    return (
        <div className='menuHeader'>
            {/* <div style={{ width: "338px", paddingTop: "10px" }} className="text-4xl text-white text-start pt-4 font-bold ml-5 italic">
                UNDER GRID
            </div> */}

            <img src={RESILIUNTLogo} className="w-[15%] pt-[10px] pb-[10px] ml-[2%]" />

            <div className='rightSection'>

                {/* <a class='mt-1 border text-blue-600 py-2 px-2  hover:text-pink-600' href="/setSecretcomp">
                    <Link to="/setSecretcomp">Set Secret</Link>
                </a> */}
                <a style={{ padding: '12px' }} href="/">
                    <HomeIcon className="text-white hover:bg-light-white"></HomeIcon>
                </a>
                {/* <a style={{ padding: '12px' }} href="/" onClick={logout}>
                    <LogoutIcon className="text-white"></LogoutIcon>
                </a> */}
                <span style={{ padding: '12px' }}>
                    {loggedName}
                </span>
            </div>
        </div >
    )
}


export default Header