import "./SecretModal.css"
// import '../main/Main.css'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';
import { setSecretPattern } from "../../services/ApiService";
import { Pattern } from "@mui/icons-material";


const SecretModal = ({ selectedDataBox }) => {

    const navigate = useNavigate();


    function setSecretClick(selectedBox) {

        console.log(selectedBox)
        let array = [];
        const data = selectedBox.map((key) => (
            array.push(key.key)
        ))
        console.log("array", array);
        localStorage.setItem("setKey", array);
        Swal.fire({
            icon: "warning",
            // title: 'Success',
            text: 'Are you sure do u want to set this as your Secret',
            showDenyButton: true,
            denyButtonText: `No`,
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.isConfirmed) {
                let localtoken = localStorage.getItem('token');
                let token = JSON.parse(localtoken).token;
                let patternFormating = localStorage.getItem('setKey');
                let finalpattern = '0' + patternFormating.replace(/,/g, 0);
                setSecretPattern(token, finalpattern).then(response => {
                    console.log(response);
                    navigate('/', { replace: true });
                    // window.location.reload(false);
                })

            }
            else if (result.isDenied) {
                navigate('/setSecretcomp', { replace: true });
                window.location.reload(false);
            }
        })

    }

    console.log("selectedDataBox111", selectedDataBox)
    let index = 0;
    const selectedBox = [];
    for (let data of selectedDataBox) {
        index += 1;
        selectedBox.push(
            <Grid item xs={3} sx={{ marginTop: '1%' }} key={data.cardIndex}>
                <div className='palette-wrapper'>
                    <div className='palette clearfix'>
                        <div className="palette-color1-wrapper">
                            <div className="palette-color1 palette-color1--color animated fadeIn palette-color1--color-red"
                                style={{ backgroundColor: data.cardColor }}>
                                <div>
                                    <label className="place-content-center mt-[20%] font-extrabold flex text-white text-2xl">{data.colorName}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Grid >
        )
    }
    return (

        <Box sx={{ flexGrow: 1 }}>
            <h2>
                Selected Secret
            </h2>
            <Grid container spacing={4}>
                {selectedBox}
            </Grid>
            <div className='next' onClick={() => { setSecretClick(selectedBox) }} style={{ float: 'right', color: 'black', marginTop: "4%" }}>
                Set Secret
            </div>

        </Box >

    )
};

export default SecretModal