import * as React from 'react';
import { useState, useEffect } from 'react';
import { getSentBox } from '../services/ApiService'
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from "react-router-dom";
import Pagination from './Pagination';
import Checkbox from '@mui/material/Checkbox';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarRateIcon from '@mui/icons-material/StarRate';
import Swal from "sweetalert2";

const SendToMailBox = () => {

    let navigate = useNavigate();

    const [checkedstate, setChecked] = React.useState(false);
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [inboxMessagesPerPage] = useState(13);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    useEffect(() => {
        GetData();
        console.log(data);
    }, []);

    const GetData = () => {
        let localtoken = localStorage.getItem('token');
        let token = JSON.parse(localtoken).token
        console.log(token);
        getSentBox(token).then(response => {
            console.log(response.data.messages);
            if (response.data.status == 'success') {
                setData(response.data.messages);
            }
        })
    }


    const setSelectedRow = (secreteKey, data) => {
        // console.log("secreteKey", secreteKey)
        // const first6 = secreteKey.slice(0, 6);
        // const remaining = secreteKey.substring(6);
        // const reversOPT = first6.split("").reverse().join(""); //OTP
        // const reversPattern = remaining.split("").reverse().join(""); //Pattern
        // const finalKey = reversPattern + reversOPT; //Key
        // console.log("finalKey", first6, remaining, reversOPT, reversPattern, finalKey);
        navigate('/readSendMessage', {
            state: {
                data: data
            }
        });
    }

    const indexOfLastMessage = currentPage * inboxMessagesPerPage;
    const indexOfFirstMessage = indexOfLastMessage - inboxMessagesPerPage;
    const currentMessage = data.slice(indexOfFirstMessage, indexOfLastMessage)

    const paginate = pageNumber => setCurrentPage(pageNumber);

    const paginateFront = () => {
        if (currentPage !== Math.ceil(data.length / inboxMessagesPerPage)) {
            setCurrentPage(currentPage + 1)
        }
    };
    const paginateBack = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    };


    return (
        <div className='card p-5 m-3 w-[99%] shadow-2xl rounded-lg  border border-grey-600 hover:shadow-lg'>
            <h2 className='text-lg font-bold'>
                Sent Messages
                <Pagination inboxMessagesPerPage={inboxMessagesPerPage} totalMessages={data.length} paginateBack={paginateBack}
                    paginateFront={paginateFront}
                    currentPage={currentPage} />
            </h2>
            <div className="grid grid-cols-1 divide-y divide-slate-400/25 text-lg font-thin	m-3 bg-[#f2f6fc]">
                {currentMessage.length !== 0 ?
                    currentMessage.map((data, index) => (
                        <div className='p-2' key={index}>
                            <Checkbox
                                checked={checkedstate}
                                onChange={handleChange}
                                inputProps={{ 'aria-label': 'controlled' }} color="default" />
                            <Checkbox
                                checked={checkedstate}
                                label="Label"
                                inputProps={{ 'aria-label': 'controlled' }} color="default"
                                icon={<StarBorderIcon />}
                                checkedIcon={<StarRateIcon />}
                            />
                            <span onClick={() => setSelectedRow(data.secreteKey, data)} className="cursor-pointer">
                                <span className='inline-flex w-[9ch] overflow-hidden'>
                                    <p className='truncate ...' title={`${data.receiver}`}>To : {data.receiver.join(", ") && data.receiver}</p>
                                </span>
                                <span className={`ml-16 whitespace-nowrap inline-flex md:w-[119ch] lg:w-[80ch] overflow-hidden`}>
                                    {data.subject}
                                    <span className='ml-2 mr-2' >-</span>
                                    <p className='truncate ...'>
                                        {data.encryptedMessage}
                                    </p>
                                </span>
                            </span>
                            <div className='mt-[-2%]  text-left'>
                                <span className='ml-[3%] mr-[3%] text-[#5f6368] text-[15px] float-right'> {new Date(data.sentTime).toLocaleString('default', { month: 'short' })} {new Date(data.sentTime).getDate()}</span>
                                <br></br>
                                <span className='ml-[3%] mr-[3%] text-[#5f6368] text-[15px] float-right whitespace-nowrap'>
                                    <span className='text-red-500'>Expires On</span> : {data.expiresIn}
                                </span>
                            </div>

                        </div>
                    ))
                    :
                    <div className='flex justify-center bg-white text-xl'>
                        No messages found
                    </div>
                }
            </div>
        </div>
    )
}


export default SendToMailBox;