import React, { useRef, useEffect } from 'react';
import DocViewer from "react-doc-viewer";

const FileViewer = ({ base64Data, fileType }) => {
    const iframeRef = useRef(null);
    useEffect(() => {
        const handleContextmenu = e => {
            e.preventDefault()
        }
        document.addEventListener('contextmenu', handleContextmenu)
        return function cleanup() {
            document.removeEventListener('contextmenu', handleContextmenu)
        }
    }, [])
    const handleIframeLoad = () => {
        console.log("rightClick")

        // Access the contentDocument through the iframeRef
        const iframeDocument = iframeRef.current.contentDocument;
        console.log("iframeDocument", iframeDocument)
        // Check if contentDocument is not null before attaching the event listener
        if (iframeDocument) {
            console.log("rightClick111")
            iframeDocument.addEventListener('contextmenu', (e) => {
                e.preventDefault();
            });
        }
    };

    return (
        <div>
            <iframe
                ref={iframeRef}
                title="file-viewer"
                src={`data:${fileType};base64,${base64Data}`}
                style={{ width: '100%', height: '100vh', border: 'none', pointerEvents: 'none' }}
            // onLoad={handleIframeLoad}
            // sandbox="allow-same-origin allow-scripts allow-forms allow-popups allow-modals allow-orientation-lock allow-pointer-lock allow-presentation allow-top-navigation"
            />
        </div>
    );
};

export default FileViewer;