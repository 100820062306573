import './App.css';
import React from "react";
import Login from "./components/login/Login";
import Header from './components/header/Header';
import useToken from './components/useToken';
import Home from './components/homePage/Home';
import { MemoryRouter } from 'react-router';
import { checkPatternValidity } from "./services/ApiService";


function App() {
  // const [barOpen, setSidebarOpen] = useState(false);
  const { token, setToken } = useToken();

  if (!token) {
    console.log("token", token)
    return <Login setToken={setToken} />
  }
  if (token) {
    // checkPatternValidity(token).then(response => {
    //   if (response.data.status === 'error') {
    //     Swal.fire({
    //       icon: 'error',
    //       text: response.data.message,
    //       confirmButtonText: "Reset",
    //     }).then((result) => {
    //       if (result.isConfirmed) {
    //         navigate('/setSecretcomp');

    //       }
    //     })
    //   }
    // })
  }
  // else {

  // }

  // const openSidebar = () => {
  //   setSidebarOpen(true);
  // }
  // const closeSidebar = () => {
  //   setSidebarOpen(false);
  // }
  return (
    < div >
      <MemoryRouter>
        <Header />
        <Home />
      </MemoryRouter>
    </div >
  );
}

export default App;
