import '../main/Main.css'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import SecretModal from '../secretModal/SecretModal';
import { useState } from 'react';

const cardArray = [

    {
        'cardIndex': '1',
        'cardColor': '#9400D3',
        'cardHash': '21343523467698794234',
        'colorName': 'Violet'
    },
    {
        'cardIndex': '2',
        'cardColor': '#4B0082',
        'cardHash': '21343523467698794234',
        'colorName': 'Indigo'
    }
    ,
    {
        'cardIndex': '3',
        'cardColor': '#0000FF',
        'cardHash': '21343523467698794234',
        'colorName': 'Blue'
    },
    {
        'cardIndex': '4',
        'cardColor': '#00FF00',
        'cardHash': '21343523467698794234',
        'colorName': 'Green'

    },
    {
        'cardIndex': '5',
        'cardColor': '#FFFF00',
        'cardHash': '21343523467698794234',
        'colorName': 'Yellow'
    },
    {
        'cardIndex': '6',
        'cardColor': '#FF7F00',
        'cardHash': '21343523467698794234',
        'colorName': 'Orange'
    },
    {
        'cardIndex': '7',
        'cardColor': '#FF0000',
        'cardHash': '21343523467698794234',
        'colorName': 'Red'
    },
    {
        'cardIndex': '8',
        'cardColor': '#ffa07a',
        'cardHash': '21343523467698794234',
        'colorName': 'Peach'
    },
    {
        'cardIndex': '9',
        'cardColor': '#00ffff',
        'cardHash': '21343523467698794234',
        'colorName': 'Sky'
    }
];




const SetSecret = () => {

    let selectedDataBox = []

    const [isShown, setIsShown] = useState(false);
    const [selectData, setdata] = useState("")
    let indexClick = 0;
    const cardClick = (cards, clickindex) => {
        indexClick += 1;
        console.log("indexClick", indexClick)
        document.getElementById(clickindex).innerHTML = indexClick
        document.getElementById(clickindex).style.display = 'block'
        if (selectedDataBox.length !== 4) {
            selectedDataBox.push(cards);
        }
        if (selectedDataBox.length === 4) {
            setdata(selectedDataBox);
            console.log("selectedDataBox", selectData);
            setIsShown(true);
        }
    }
    const box = [];
    for (let data of cardArray) {
        box.push(
            <div>
                <Grid item xs={4} sx={{ marginTop: '2%' }} className="justify-center flex">
                    <div
                        onClick={() => { cardClick(data, cardArray.indexOf(data)) }}
                        className="p-[16%] w-[0px] h-[50px] rounded-[15%] hover:shadow-lg cursor-pointer	drop-shadow-lg"
                        style={{ backgroundColor: data.cardColor }}>
                        <label id={cardArray.indexOf(data)} className="flex justify-center text-white text-2xl"></label>
                    </div>
                </Grid >
                <div>
                    <label className="flex justify-center mt-5 text-xl font-light">{data.colorName}</label>
                </div>
            </div>
        )
    }
    return (
        // <main>
        <div className="main__container flex-1">
            <Box sx={{ flexGrow: 1 }}>
                {isShown ? '' : <h2 className='text-lg' style={{ marginTop: '-2%', color: 'red' }}>
                    *********** Set Secret ***********
                </h2>}
                {
                    isShown ?
                        (<Grid container spacing={3}>
                            <SecretModal selectedDataBox={selectData} />
                        </Grid>)
                        :
                        (<div className="grid grid-cols-3 content-center">
                            {box}
                        </div>)
                }

            </Box>
        </div>
        // </main >
    )
}




export default SetSecret;