import React from "react";
import Avatar from '@mui/material/Avatar';
import { useLocation } from "react-router-dom";
import Stack from '@mui/material/Stack';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import IconButton from '@mui/material/IconButton';
import { useState, useRef } from 'react';
import CryptoJS from 'crypto-js';
import { generateDecryptSessionKey, getDocuments, getSecretePatternToReadAsOTP } from "../../services/ApiService";
import parse from 'html-react-parser'
import Chip from '@mui/material/Chip';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";
import FileViewer from '../FileViewer';
const AccordionMessage = ({ arrayOfData, patternCode }) => {
    console.log("arrayOfData", arrayOfData, patternCode)
    const location = useLocation();
    const [openAccordion, setOpenAccordion] = React.useState();
    const [getAttachments, setgetAttachments] = useState([]);
    const [data1, setdata1] = useState('')
    const [dataResponse, setdataResponse] = useState('');
    const [UUID, setUUID] = useState('');
    const [clickStatus, setClickStatus] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [content, setContent] = useState('');
    let keyKeyKey;


    const [documentData, setDocumentData] = useState(null);
    const [fileType, setFileType] = useState(null);
    const [showOtherComponent, setShowOtherComponent] = useState(false);


    const handleOpen = (value, message) => {
        console.log("patternCode", value)
        setOpenAccordion(openAccordion === value ? 0 : value);
        if (openAccordion !== value) {
            specificData(message)
        }
    }

    const specificData = (message) => {
        let localtoken = localStorage.getItem('token');
        let token = JSON.parse(localtoken).token;
        let obj = {
            uuid: message.uuid,
            mainUuid: message.repliedUuid,
            messageType: message.messageType
        }
        console.log("obj", obj)
        getSecretePatternToReadAsOTP(token, obj).then(response => {
            let pattern = response.data.secretPattern;
            const reversPattern = response.data.secretPattern.split("").reverse().join(""); //Pattern
            let loginUserId = localStorage.getItem('userid')
            let obj1 = {
                uuid: message.uuid,
                mainUuid: message.repliedUuid,
                messageType: message.messageType,
                patternCode: reversPattern,
                sender: loginUserId
            }
            console.log("obj", obj1)
            generateDecryptSessionKey(token, obj1).then(response => {
                setdataResponse(response.data.message.message);
                if (response.data.message.status === 'success') {
                    let reverseDecryptedOTP = response.data.message.message.decryptedOTP;
                    let originalOTP = reverseDecryptedOTP.split("").reverse().join("");
                    console.log("originalOTP", originalOTP);
                    keyKeyKey = reversPattern + originalOTP + '00';
                    console.log("key", keyKeyKey, response.data.message.message.encryptedMessage);
                    const decrypted = CryptoJS.AES.decrypt(response.data.message.message.encryptedMessage, keyKeyKey).toString(CryptoJS.enc.Utf8);
                    console.log("222", decrypted)
                    setdata1(JSON.parse(decrypted))
                    console.log("data1", data1);
                    setgetAttachments(response.data.message.message.attachments);
                    console.log("getAttachments", Array.isArray(getAttachments))
                }
                // removArrayByIndex()
            })
        })

    }


    const documents = (fileId, filename, message) => {
        console.log("location.state.downloadOnly", message.repliedUuid)
        let localtoken = localStorage.getItem('token');
        let token = JSON.parse(localtoken).token;
        if (message.repliedUuid === null) {
            console.log("message.repliedUuid", message.repliedUuid)
            message.repliedUuid = ''
        }
        console.log("message['repliedUuid']", message['repliedUuid'])
        let obj = {
            uuid: message.uuid,
            mainUuid: message['repliedUuid'],
            messageType: message.messageType,
            fileId: fileId,
        }
        console.log("repliedUuid", message.repliedUuid)
        getDocuments(token, obj).then((response) => {
            console.log("fileType:::", response.data['attachments'])
            // console.log("response.data.attachments[0]", response.data['attachments'].filename)
            if (response.data.status === 'success') {
                setDocumentData(response.data['attachments'].data)
                setFileType(response.data['attachments'].fileType);
                const linkSource = `data:${response.data['attachments'].fileType};base64,${response.data['attachments'].data}`;
                console.log("fileType:::", response.data['attachments'].fileType)

                if (dataResponse.downloadOnly === true) {
                    const downloadLink = document.createElement("a");
                    downloadLink.href = linkSource;
                    downloadLink.download = filename;
                    downloadLink.click();
                }
                if (dataResponse.viewOnly === true) {
                    setShowOtherComponent(!showOtherComponent);

                    // console.log("viewOnly")
                    // if (response.data['attachments'].fileType === 'image/png') {
                    //     console.log("image")
                    //     var image = new Image();
                    //     image.src = `data:${response.data['attachments'].fileType};base64,` + response.data['attachments'].data;

                    //     var w = window.open("", '', "toolbars=no,scrollbars=no,status=no,resizable=no");
                    //     w.document.write("<html style={height:20px;width:20px;}<head><title>" + filename + "</title><style>body{margin: 0px;}</style></head>");
                    //     w.document.write(image.outerHTML);
                    // }
                    // else {
                    //     let pdfWindow = window.open("");
                    //     pdfWindow.document.write("<html<head><title>" + filename + "</title><style>body{margin: 0px;}</style></head>");
                    //     pdfWindow.document.write(`<body><embed width='100%' height='100%' src=data:${response.data['attachments'].fileType};base64, " + encodeURI(response.data['attachments'].data) + "#toolbar=0&navpanes=0&scrollbar=0></embed></body></html>`);
                    //     pdfWindow.document.close();
                    // }
                }
            }
        })
    }

    const replyClick = (uuid) => {
        setClickStatus('reply')
        console.log("click reply");
        setUUID(uuid)
        // setShowModal(true);
        // if (
        //     document.getElementById("menu") &&
        //     document.getElementById("main")
        // ) {
        //     document.getElementById("menu").style.width = "250px";
        //     document.getElementById("main").style.marginRight = "250px";
        // }
    }
    const replyAllClick = (uuid) => {
        setClickStatus('replyAll')
        setUUID(uuid)


    }
    const forwardClick = (uuid) => {
        setClickStatus('forward')
        setUUID(uuid)
    }

    const handleCloseViewer = () => {
        setShowOtherComponent(!showOtherComponent);
    };

    return (
        <div>
            {arrayOfData.map((message, index) => {
                return (
                    showOtherComponent ?
                        <div>
                            <button className="float-right text-red-500 text-lg font-extrabold pr-5" title="close Viewer" onClick={handleCloseViewer}>X</button>
                            <FileViewer base64Data={documentData} fileType={fileType} />
                        </div>
                        :
                        <div>
                            <div className={`${openAccordion === index ? " border-b" : ""}  text-black rounded border-grey-600 m-9 mt-0 mb-0`}>
                                <div>
                                    <Accordion open={openAccordion === index} key={index} class="ml-12">
                                        <AccordionHeader onClick={() => handleOpen(index, message)}>
                                            <Stack direction="row" spacing={50}>
                                                <Avatar className="uppercase" style={{ backgroundColor: "#081a51d6" }}>{message.sender.charAt(0)}</Avatar>
                                                <div class="justify-items-start pl-3">

                                                    {/* <br /> */}
                                                    {/* {openAccordion}{index} */}
                                                    {
                                                        openAccordion === index ? (
                                                            <div>
                                                                <span class="flex ml-[-157%]">
                                                                    {message.sender}
                                                                </span>
                                                                <span class="text-xs flex text-slate-400 ml-[-157%]">
                                                                    {message.receiver && message.receiver.join(",")}
                                                                </span>
                                                            </div>
                                                        )
                                                            :
                                                            (
                                                                <div>
                                                                    <span class="flex ml-[-165%]">
                                                                        {message.sender}
                                                                    </span>
                                                                    <span class="text-xs flex text-slate-400 ml-[-165%]">
                                                                        {message.uuid}
                                                                    </span>
                                                                </div>
                                                            )

                                                    }

                                                </div>
                                                <div style={{ width: "", justifyContent: 'end', display: 'flex' }}>
                                                    <span class="text-xs text-slate-400  mt-5" >
                                                        {message.sentTime}
                                                    </span>

                                                    {/* <span class="ml-4 mt-1 text-slate-400">
                                                    <IconButton aria-label="Not Stared">
                                                        <StarBorderIcon />
                                                    </IconButton>
                                                </span> */}

                                                </div>
                                            </Stack>
                                        </AccordionHeader>
                                        <AccordionBody style={{ whiteSpace: 'pre-wrap', marginLeft: '10px', marginLeft: '4.5%', marginTop: "2%", fontWeight: "normal" }}>
                                            {parse(data1)}
                                            <div>
                                                <div className="flex">
                                                    {Array.isArray(getAttachments)}
                                                    {
                                                        getAttachments.length !== 0 ? (
                                                            getAttachments.map((file, index) => {
                                                                return (
                                                                    <div className="border border-[#e5e5e5] h-[120px] w-[180px] ml-[2px] mb-[3px] mt-[80px] cursor-pointer"
                                                                        onClick={() => documents(file.fileId, file.filename, message)}>
                                                                        <div className='relative h-[85px] items-center flex justify-center'>
                                                                            <img src="https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_image_x16.png" className="w-[25px] h-[25px] text-center" />
                                                                        </div>
                                                                        <div className='border-t-[1.5px] border-[#e5e5e5] bg-[#f5f5f5] text-[12px] h-[33px] flex items-center whitespace-nowrap overflow-hidden'>
                                                                            <span className='truncate ...'>{file.filename}</span>
                                                                        </div>
                                                                        {/* <span> {file.size}</span> */}
                                                                    </div>
                                                                )

                                                            })
                                                        ) : ""

                                                    }
                                                </div>
                                            </div>
                                            <div class="mt-10 ml-1 mb-4">
                                                <Stack direction="row" spacing={1}>
                                                    <Chip
                                                        label="Reply"
                                                        deleteIcon={<DoneIcon className="cursor-pointer" />}
                                                        variant="outlined"
                                                        className="cursor-pointer"
                                                        onClick={() => replyClick(location.state.uuid)}
                                                    />
                                                    <Chip disabled={!dataResponse.reply}
                                                        label="Reply all"
                                                        deleteIcon={<DeleteIcon className="cursor-pointer" />}
                                                        variant="outlined"
                                                        className="cursor-pointer"
                                                        onClick={() => replyAllClick(location.state.uuid)}
                                                    />
                                                    <Chip disabled={!dataResponse.forwardable}
                                                        label="Forward"
                                                        deleteIcon={<DeleteIcon className="cursor-pointer" />}
                                                        variant="outlined"
                                                        className="cursor-pointer"
                                                        onClick={() => forwardClick(location.state.repliedUuid)}
                                                    />
                                                </Stack>
                                            </div>
                                        </AccordionBody>
                                    </Accordion>
                                </div >
                            </div>
                        </div>
                )
            })}

        </div >
    )
}

export default AccordionMessage