import React from "react";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Pagination = ({ inboxMessagesPerPage, totalMessages, paginateFront, paginateBack, currentPage }) => {
    const pageNumber = [];
    for (let i = 1; i <= Math.ceil(totalMessages / inboxMessagesPerPage); i++) {
        pageNumber.push(i);
    }
    return (
        // <div className="float-right">
        //     {pageNumber.map(number => (
        //         <span key={number} className="select-none space-x-1 text-gray-700 border border-indigo-600">
        //             <a onClick={() => paginate(number)} className="rounded-md px-4 py-2 whitespace-nowrap	">
        //                 {number}
        //             </a>
        //         </span>
        //     ))}
        // </div>
        <div className='float-right'>
            {/* <div>
                <p className='text-sm text-gray-700'>
                    Showing
                    <span className='font-medium'>{Math.ceil(currentPage * inboxMessagesPerPage)}</span>
                    to
                    <span className='font-medium'> {currentPage * inboxMessagesPerPage} </span>
                    of
                    <span className='font-medium'> {totalMessages} </span>
                    results
                </p>
            </div> */}
            {/* <nav className='block'></nav>*/}
            <div>

                <nav
                    className='relative z-0 inline-flex rounded-md shadow-sm -space-x-px'
                    aria-label='Pagination'
                >
                    <p color="gray" className="font-normal mr-5">
                        {/* <strong className="text-gray-900">{currentPage * inboxMessagesPerPage}</strong> of{totalMessages} */}
                        page
                        <span className='font-medium mr-1 ml-3'>{currentPage}</span>
                        /
                        <span className='font-medium'> {Math.ceil(totalMessages / inboxMessagesPerPage)} </span>
                    </p>
                    <ArrowBackIosIcon
                        onClick={() => {
                            paginateBack();
                        }}
                        className='relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'
                    >
                        <span>Previous</span>
                    </ArrowBackIosIcon>
                    {/* <p color="gray" className="font-normal">
                        Page <strong className="text-gray-900">{currentPage * inboxMessagesPerPage}</strong> of{totalMessages}
                </p> */}
                    <ArrowForwardIosIcon
                        onClick={() => {
                            paginateFront();
                        }}
                        className='relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'
                    >
                        <span>Next</span>
                    </ArrowForwardIosIcon>
                </nav>
            </div>
        </div >
    )
}

export default Pagination