import React from 'react';
import { useState, useEffect } from 'react';
import { listOfMessages } from '../../services/ApiService'
// import './Grid.css';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import { useNavigate } from "react-router-dom";
import AnnouncementIcon from '@mui/icons-material/Announcement';
import Tooltip from '@mui/material/Tooltip';


const useStyles = makeStyles({
    root: {
        marginTop: '-3% !important',
        width: "auto !important",
        height: "auto !important",
        textAlign: "center !important",
        padding: '20px 35px !important'
    },
    container: {
        maxHeight: 440,
        marginTop: '2%'
    },
    heading: {
        textAlign: "left",
        fontWeight: "500",
        fontSize: "25px",
        lineHeight: "42px"
    }
});

const Grid = () => {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [data, setData] = useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);


    useEffect(() => {
        GetData();
        console.log(data);
    }, []);

    const GetData = () => {
        let localtoken = localStorage.getItem('token');
        let token = JSON.parse(localtoken).token
        console.log(token);
        listOfMessages(token).then(response => {
            console.log(response.data.messages);
            if (response.data.status !== 'no messages found') {
                setData(response.data.messages);
            }
        })
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    let navigate = useNavigate();


    const setSelectedRow = (uuid, subject, sender) => {
        navigate('/reciverPage', {
            state: {
                uuid: uuid,
                subject: subject,
                mailfrom: sender
            }
        });
    }


    return (
        <div style={{ marginTop: '3%' }}>
            {
                < Paper className={classes.root} >
                    <h1 className={classes.heading}>
                        Inbox
                    </h1>
                    < TableContainer className={classes.container} >
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Subject</TableCell>
                                    <TableCell>Date/Time</TableCell>
                                    <TableCell>Created by</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
                                    return (
                                        <TableRow onClick={() => setSelectedRow(row.uuid, row.subject, row.sender)}
                                            key={row.uuid} style={{ cursor: "pointer" }}>
                                            <TableCell>{row.subject}</TableCell>
                                            <TableCell>{row.sentTime}</TableCell>
                                            <TableCell>{row.sender}</TableCell>
                                            <TableCell>
                                                <Tooltip title="Request for Secret Pattern">
                                                    <AnnouncementIcon className="text-black"></AnnouncementIcon>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer >
                    < TablePagination
                        rowsPerPageOptions={[5, 10, 15]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Paper >

            }
        </div >

    );
}



export default Grid;