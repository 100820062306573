import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { getSecretePatternToReadAsOTP, getPreviousMessages } from "../../services/ApiService";
import Swal from "sweetalert2";
import "./ReadAsOTP.css";
import { ToastContainer, toast, Zoom, Flip, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import QRCode from 'react-qr-code';
import html2canvas from 'html2canvas';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const ReadAsOTP = () => {
    const [first, setFirst] = useState('');
    const [second, setSecond] = useState('');
    const [third, setThird] = useState('');
    const [fourth, setFourth] = useState('');
    const [five, setFive] = useState('');
    const [six, setSix] = useState('');
    const [seven, setSeven] = useState('');
    const [eight, setEight] = useState('');
    const [state, setState] = useState([]);
    const [qrobj, setQRObj] = useState({});
    const [back, setBack] = useState('#FFFFFF');
    const [fore, setFore] = useState('#000000');
    const [size, setSize] = useState(200);
    const location = useLocation();
    let navigate = useNavigate();

    const containerRef = useRef(null);


    useEffect(() => {
        GetReadAsOTP();
    }, []);

    const GetReadAsOTP = () => {
        let localtoken = localStorage.getItem('token');
        let token = JSON.parse(localtoken).token;
        console.log("fullData", location.state.fullData)
        let obj = {
            uuid: location.state.uuid,
            mainUuid: location.state.fullData.repliedUuid,
            messageType: location.state.fullData.messageType
        }
        setQRObj(obj)
        console.log("obj", qrobj)
        getSecretePatternToReadAsOTP(token, obj).then(response => {
            const reversPattern = response.data.secretPattern.split("").reverse().join(""); //Pattern
            let Pattern = reversPattern.replaceAll('0', '');
            console.log("response::::reversPattern", reversPattern);
            // toast.info(`${response.data.secretPattern}`, {
            //     position: toast.POSITION.TOP_RIGHT,
            //     // className: "toast-message",
            //     hideProgressBar: false,
            //     theme: "colored",
            //     closeOnClick: true,
            //     // transition: "Zoom"
            // });
        })
    }

    const validateReadOTP = () => {
        console.log("SUBMIT OTP")
        let finalpattern = first + second + third + fourth + five + six + seven + eight;
        const reversPattern = finalpattern.split("").reverse().join("");
        let loginUserId = localStorage.getItem('userid')
        let localtoken = localStorage.getItem('token');
        let token = JSON.parse(localtoken).token;
        let obj = {
            uuid: location.state.uuid,
            mainUuid: location.state.fullData.repliedUuid,
            messageType: location.state.fullData.messageType,
            patternCode: reversPattern,
            userName: loginUserId
        }
        getPreviousMessages(token, obj).then(response => {
            console.log("DAAAAA", response.data.status)
            if (response.data.status === `failed`) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: response.data.message,
                    confirmButtonText: "OK",
                }).then((result) => {
                    if (result.isConfirmed) {
                        // window.location.reload(false);
                    }
                })
            }
            else if (response.data.status === 'success') {
                // let atta = response.data.message.attachments;
                // console.log(" response.data.messages[0]", response.data.messages)
                console.log("11111:response.data.message.attachments")
                let newList = removeItem(response.data.messages, 0)
                // setState(newList)
                console.log("state", newList);
                // return
                navigate('/MessageBody', {
                    state: {
                        fullData: response.data,
                        patternCode: reversPattern,
                        zeroIndex: response.data.messages[0],
                        withOutZeroIndex: newList,
                    }
                });
            }
        })
    }

    const removeItem = (array, id) => {
        console.log("array", array, id)
        return array.splice((id, 1))

    }

    const clickEvent = (e, last) => {
        const currentInputValue = e.target.value.trim();
        if (currentInputValue !== '') {
            document.getElementById(last).focus();
        }
    }


    const handleQrDownload = () => {
        const container = containerRef.current;

        if (container) {
            // Make the hidden content visible for the screenshot
            const hiddenContent = container.querySelectorAll('.hidden-content');
            hiddenContent.forEach(element => {
                element.style.visibility = 'visible';
            });
            html2canvas(container, { scale: 2, useCORS: true }).then(canvas => {
                const context = canvas.getContext('2d');
                console.log("3333")
                // // Add padding to the image by drawing a rectangle with background color
                // const padding = 20; // Adjust the padding value as needed
                // context.fillStyle = '#ffffff'; // Background color
                // context.fillRect(0, 0, canvas.width, canvas.height);
                // context.drawImage(canvas, padding, padding, canvas.width - 2 * padding, canvas.height - 2 * padding);
                // Convert canvas to data URL
                const dataUrl = canvas.toDataURL('image/png');

                // Reset the visibility of hidden content
                hiddenContent.forEach(element => {
                    element.style.visibility = 'hidden';
                });

                // Create a temporary link element
                const link = document.createElement('a');
                link.href = dataUrl;
                link.download = 'qrcode' + '.png';

                // Simulate a click to trigger the download
                link.click();
            });
        }
    };


    return (
        <div>
            <div className=' w-full grid place-items-center'>
                <div className='mt-[8%]'>
                    <button onClick={handleQrDownload} className="ml-[100%]">
                        {<FileDownloadOutlinedIcon className="text-orange-700 flex mt-[9px] ml-[1rem]" />}
                    </button>
                    <div ref={containerRef}>
                        <QRCode
                            title="QR"
                            value={qrobj.uuid + " " + qrobj.mainUuid + " " + qrobj.messageType}
                            bgColor={back}
                            fgColor={fore}
                            size={size === '' ? 0 : size}
                        />
                        <div className="hidden-content text-[5px]" style={{ visibility: 'hidden', padding: '5%' }}>
                            Subject : {location.state.fullData.subject}
                            <div className='p-[5%] text-red-500'>
                                Scan this on Resiliunt
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center  mt-[2%]">
                    <label className="text-black font-bold ml-5 text-xl">
                        Scan QR to receive the session key
                    </label>
                </div>
                <div style={myComponentStyle.inputfield} className="gap-x-8 text-black mt-[4%]">
                    <input maxLength={1} autoComplete="off" id='ist' onKeyUp={(e) => clickEvent(e, 'sec')} style={myComponentStyle.input} onChange={event => { setFirst(event.target.value) }} />
                    <input maxLength={1} autoComplete="off" id='sec' onKeyUp={(e) => clickEvent(e, 'third')} style={myComponentStyle.input} onChange={event => { setSecond(event.target.value) }} />
                    <input maxLength={1} autoComplete="off" id='third' onKeyUp={(e) => clickEvent(e, 'fourth')} style={myComponentStyle.input} onChange={event => { setThird(event.target.value) }} />
                    <input maxLength={1} autoComplete="off" id='fourth' onKeyUp={(e) => clickEvent(e, 'fifth')} style={myComponentStyle.input} onChange={event => { setFourth(event.target.value) }} />
                    <input maxLength={1} autoComplete="off" id='fifth' onKeyUp={(e) => clickEvent(e, 'sixth')} style={myComponentStyle.input} onChange={event => { setFive(event.target.value) }} />
                    <input maxLength={1} autoComplete="off" id='sixth' onKeyUp={(e) => clickEvent(e, 'seventh')} style={myComponentStyle.input} onChange={event => { setSix(event.target.value) }} />
                    <input maxLength={1} autoComplete="off" id='seventh' onKeyUp={(e) => clickEvent(e, 'eighth')} style={myComponentStyle.input} onChange={event => { setSeven(event.target.value) }} />
                    <input maxLength={1} autoComplete="off" id='eighth' style={myComponentStyle.input} onChange={event => { setEight(event.target.value) }} />
                </div>
                <div className='mt-[5%]'>
                    <button style={myComponentStyle.submit} onClick={validateReadOTP}>Submit</button>
                </div>
            </div>
            {/* <ToastContainer limit={1} transition={Slide} autoClose={116000} className="mt-[3%] text-3xl" /> */}
        </div>
    )
}

export default ReadAsOTP;


const myComponentStyle = {
    inputfield: {
        width: '100 %',
        display: "flex",
        justifyContent: ' space - around',
        // marginTop: '-20%'
    },
    input: {
        height: "3em",
        width: "3em",
        borderBottom: '2px solid #6676aa82',
        outline: "none",
        textAlign: "center",
        fontSize: "1.5em",
        backgroundColor: "#ffffff",
    },
    submit: {
        backgroundColor: '#044ecf',
        border: "none",
        outline: "none",
        fontSize: "1.2em",
        padding: "0.8em 2em",
        color: "#ffffff",
        borderRadius: " 0.1em",
        margin: "1em auto 0 auto",
        cursor: "pointer",
    }
}