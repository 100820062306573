import { useEffect, useState } from "react";
import InboxIcon from '@mui/icons-material/Inbox';
import AddIcon from '@mui/icons-material/Add';
// import PasswordIcon from '@mui/icons-material/Password';
import Tooltip from '@mui/material/Tooltip';
import { Link } from "react-router-dom";
import logoutIcon from "../../../src/assets/logoutIcon.png";
import React from 'react';


import Main from "../main/Main";
import SetSecret from "../setSecret/SetSecret";
import { Route, Routes } from 'react-router-dom';
import ReadAsOTP from '../reciver/ReadAsOTP';
import AppBase from '../composeMessage/Message';
import OTP from '../composeMessage/OTP';
import SendTo from '../composeMessage/SendTo';
import Inbox from '../inbox/Inbox';
import MailBody from '../inbox/mailBody';
import Grid from '../inbox/messages';
import SendToMailBox from '../SendToMailBox'
import SendIcon from '@mui/icons-material/Send';
import KeyIcon from '@mui/icons-material/Key';
// import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ReadSendMeaasge from '../readSendMessage';
import QRCodeComponent from '../composeMessage/QRCode'
import { checkPatternValidity } from "../../services/ApiService";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

import Login from "../login/Login";

const Home = () => {
    const [open, setOpen] = useState(false);
    const Menus = [
        { title: "Inbox", src: <InboxIcon />, gap: true, link: '/Inbox' },
        // { title: "Sent Message", src: <IosShareIcon />, gap: true, link: '/SendToMailBox' },
        { title: "Sent Message", src: <SendIcon />, gap: true, link: '/SendToMailBox' },
        { title: "Create New Message", src: <AddIcon />, gap: true, link: '/Main' },
        { title: "Set Secret", src: <KeyIcon />, gap: true, link: '/setSecretcomp' },
    ]

    const [activeLinkIndex, setActiveLinkIndex] = useState(0);

    const logout = () => {
        localStorage.clear();
        window.location.reload(true)
    }


    return (
        <div className="flex">
            <div className={` ${open ? "w-72" : "w-20"} 
            duration-300 p-5 pt-[3%] bg-dark-purple fixed h-[100vh]`}>
                {/* <img
                    src={hamburgImg}
                    className={`absolute cursor-pointer ${open ? "ml-[88%]" : "ml-[55%]"}
                    rounded-full top-1 w-7 border-2 border-dark-purple ${!open && "rotate-180"}`}
                    onClick={() => setOpen(!open)}
                /> */}
                <ul className="pt-6">
                    {Menus.map((Menu, index) => (
                        <li key={index} onClick={() => setActiveLinkIndex(index)} className={`flex  rounded-md p-2 hover:bg-light-white text-gray-300 text-sm items-center gap-x-4 ${Menu.gap ? "mt-9" : "mt-2"} ${activeLinkIndex === index ? 'bg-light-white' : ''} `} >
                            <Link to={Menu.link} className="cursor-pointer">
                                {(!open ?
                                    <Tooltip title={Menu.title} placement="right" arrow>
                                        {Menu.src}
                                    </Tooltip>
                                    :
                                    <span>
                                        {Menu.src}
                                    </span>)}

                                <span className={`${!open && "hidden"} origin-left duration-200 text-lg	m-3`}>
                                    {Menu.title}
                                </span>
                            </Link>
                        </li>
                    ))}
                    <li className={`${!open ? "" : "mt-[30rem]"} absolute bottom-[4%] left-[32%]  cursor-pointer `}>
                        <Link to="/Login">
                            <img src={logoutIcon} alt="img" title="logout" onClick={() => logout()} className="text-white" style={{ filter: " brightness(0) invert(1)", width: "30px" }} />
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="flex-1 h-[89vh] text-black mt-[5%] ml-[4%]">
                <Contents />
            </div>
        </div>
    )
}

function Contents() {
    return (
        <Routes>
            <Route path="/Main" element={<Main />}></Route>
            <Route path="/" element={<Inbox />}></Route>
            <Route path="/setSecretcomp" element={<SetSecret />} ></Route>
            <Route path="/reciverPage" element={<ReadAsOTP />} ></Route>
            <Route path="/AppBase" element={<AppBase />} ></Route>
            <Route path="/OTP" element={<OTP />} ></Route>
            <Route path="/SendTo" element={<SendTo />} ></Route>
            <Route path="/SendToMailBox" element={<SendToMailBox />} ></Route>
            <Route path="/Inbox" element={<Inbox />} ></Route>
            <Route path='/MessageBody' element={<MailBody />}></Route>
            <Route path='/Grid' element={<Grid />}></Route>
            <Route path="/readSendMessage" element={<ReadSendMeaasge />} ></Route>
            <Route path="/Login" element={<Login />} ></Route>
            <Route path="/QRCodeComponent" element={<QRCodeComponent />} ></Route>
        </Routes>
    )

}


export default Home;