import './Login.css';
import logo from "../../../src/assets/RESILIUNTLogo.png";
import logo1 from "../../../src/avatar.png";
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import Swal from "sweetalert2";
import { keycloak, login, googleredirect, getAccessToken } from '../../services/ApiService'
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ToastContainer, toast, Zoom, Flip, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import googleImage from "../../assets/googleImage.jpg";
import Keycloak from 'keycloak-js';
import md5 from "md5";

export default function Login({ setToken }) {

    // const Token = { "token": "1232324" }
    // const [username, setUserName] = useState();
    // const [password, setPassword] = useState();
    const [formValues, setFormValues] = useState({
        username: {
            value: '',
            error: false,
            errorMessage: 'You must enter a UserName'
        },
        password: {
            value: '',
            error: false,
            errorMessage: 'You must enter an Password'
        },
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: {
                ...formValues[name],
                value
            }
        })
        console.log("formData", formValues)
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const formFields = Object.keys(formValues);
        let newFormValues = { ...formValues }

        for (let index = 0; index < formFields.length; index++) {
            const currentField = formFields[index];
            const currentValue = formValues[currentField].value;

            if (currentValue === '') {
                console.log("ifff")
                newFormValues = {
                    ...newFormValues,
                    [currentField]: {
                        ...newFormValues[currentField],
                        error: true
                    }
                }
            }
        }

        setFormValues(newFormValues)
        console.log("setFormValues", formValues.username.value)
        submitApi(formValues.username.value, formValues.password.value)
    }

    const submitApi = (username, password) => {
        // e.preventDefault();
        const body = {
            userName: username,
            password: md5(password),
            // grant_type: "password",
            // client_id: 'rw_viewer',
        };
        console.log("body", body)
        getAccessToken(body).then(loginres => {
            console.log("login resp", loginres)
            if (loginres.data.status === 'success') {
                localStorage.setItem('userid', username);
                localStorage.setItem('username', username);
                setToken({ 'token': loginres.data.access_token });
            }
            else {
                toast.error("Try with correct user & password", {
                    position: toast.POSITION.TOP_CENTER,
                    className: "toast-message",
                    hideProgressBar: true,
                    theme: "colored",
                    closeOnClick: true,
                    // transition: "Zoom"
                });
            }
        }).catch((err) => {
            toast.error("Try with correct user & password", {
                position: toast.POSITION.TOP_CENTER,
                className: "toast-message",
                hideProgressBar: true,
                theme: "colored",
                closeOnClick: true,
                // transition: "Zoom"
            });
        })
        return
        keycloak(body).then(response => {
            console.log("response", response);
            if (response.status === 200) {
                let responseData = response.data;
                let access_token = responseData.access_token;
                login(responseData.access_token, username, password).then(loginres => {
                    console.log("login resp", loginres)
                    if (loginres.data.status === 'login successful') {
                        localStorage.setItem('userid', loginres.data.data);
                        localStorage.setItem('username', username);
                        setToken({ 'token': responseData.access_token });
                    }
                })
            }
        }).catch((err) => {
            toast.error("Try with correct user & password", {
                position: toast.POSITION.TOP_CENTER,
                className: "toast-message",
                hideProgressBar: true,
                theme: "colored",
                closeOnClick: true,
                // transition: "Zoom"
            });
        })

    }

    const redirectGoogleURL = () => {
        window.open("https://auth.multitenancy.realware.app/auth/realms/undergrid/protocol/openid-connect/auth?client_id=rw_viewer&redirect_uri=https://sccom.realware.app/&response_type=code&scope=openid&kc_idp_hint=google", "_self");
    }


    useEffect(() => {
        const url = window.location.href;
        console.log("url", url);
        const queryParams = new URLSearchParams(window.location.search);
        let code = queryParams.get('code');
        console.log("code", code);
        if (code !== null) {
            console.log("navigateURL")
            let obj = {
                accessCode: code
            }
            googleredirect(obj).then(response => {
                if (response.status === 200) {
                    let responseData = response.data;
                    console.log("responseData", responseData)
                    if (responseData.status === 'success') {
                        localStorage.setItem('userid', responseData.userName);
                        localStorage.setItem('username', responseData.userName);
                        setToken({ 'token': responseData.token });
                    }
                }
            })

        }
    }, []);


    return (
        <body class='loginimag'>
            {/* <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css" />
            <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" />
            <script src="https://code.jquery.com/jquery-3.5.1.min.js"></script>
            <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js"></script>
            <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/js/bootstrap.min.js"></script> */}
            <div class="login-form">
                <form action="/examples/actions/confirmation.php" method="post" noValidate onSubmit={handleSubmit}>
                    {/* <div class="avatar"> */}
                    {/* <img src={logo1} alt="Avatar" /> */}
                    {/* </div> */}
                    <h2 class="text-center">Welcome .. You are now entering</h2>
                    <div class="avatar1">
                        <img src={logo} alt="Avatar" />
                    </div>
                    <div class="form-group">
                        {/* <input type="text" class="form-control" name="username" placeholder="Username" required="required" /> */}
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            <AccountCircle sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                            <TextField fullWidth id="input-with-sx" label="Username" variant="standard"
                                autoComplete="off"
                                type="text"
                                required
                                name='username'
                                value={formValues.username.value}
                                onChange={handleChange}
                                error={formValues.username.error}
                                helperText={formValues.username.error && formValues.username.errorMessage}
                            />
                        </Box>
                    </div>
                    <br></br>
                    <div class="form-group">
                        {/* <input type="password" class="form-control" name="password" placeholder="Password" required="required" /> */}
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            <LockIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                            <TextField fullWidth id="input-with-sx" label="Password" variant="standard"
                                type="password" placeholder="Password"
                                autoComplete="off"
                                required
                                name='password'
                                value={formValues.password.value}
                                onChange={handleChange}
                                error={formValues.password.error}
                                helperText={formValues.password.error && formValues.password.errorMessage}
                            />
                        </Box>
                    </div>
                    <div class="form-group">
                        <button type="submit" onClick={handleSubmit} class="btn btn-primary btn-lg btn-block h-[35px]">Sign in</button>
                    </div>
                    {/* <div class="bottom-action clearfix">
                        <label class="float-left form-check-label"><input type="checkbox" /> Remember me</label>
                        <a href="#" class="float-right">Forgot Password?</a>
                    </div> */}
                    <hr></hr>
                </form>

                <div className='bg-[#fbfbfb] mt-[-1%] rounded-[2px] text-[#7a7a7a]'>
                    <h4 className='justify-center flex text-slate-300 text-base'>
                        Or sign in with
                    </h4>

                    <div className="justify-center flex mt-6">
                        <button onClick={redirectGoogleURL} className="border-2 bg-[#f4f4f4] w-[70%] btn-lg inline-flex p-1 mb-5">
                            {/* <div className=""> */}
                            <img src={googleImage} alt="" className='h-[20px] mt-1.5' />
                            <div className='ml-12'> Google</div>
                            {/* </div> */}

                        </button>
                    </div>
                </div>
                {/* <p class="text-center small">Don't have an account? <a href="#">Sign up here!</a></p> */}
            </div >
            <ToastContainer limit={1} transition={Slide} />
        </body >
    )
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
}

