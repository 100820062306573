import axios from 'axios';

// const baseUrl = 'https://api.undergrid.realware.tech';
const baseUrl = "https://sccom.realware.tech";
const keycloakUrl = 'https://auth.multitenancy.realware.app/auth/realms/undergrid/protocol/openid-connect/token';




export async function keycloak(data) {
    let formBody = [];
    for (let property in data) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(data[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const basicHeaders = {
        "Content-Type": "application/x-www-form-urlencoded",
    };
    const options = { headers: basicHeaders };
    return await axios.post(keycloakUrl, formBody, options);
}


export async function getAccessToken(data) {
    console.log("body", data)
    let obj = {
        userName: data.userName,
        password: data.password
    }
    const basicHeaders = {
        "Content-Type": "application/x-www-form-urlencoded",
    };
    const options = { headers: basicHeaders };
    const url = baseUrl + "/auth/getAccessToken"
    return await axios.post(url, obj)
}


export async function login(token, username, password) {
    let obj = {
        userName: username,
        password: password,
    };
    const basicHeaders = {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
    };
    const options = { headers: basicHeaders };
    const url = baseUrl + "/sccom/login"
    return await axios.post(url, obj, options)
}


export async function generateEncryptSessionKey(token, obj, files) {
    let fd = new FormData();
    for (let i = 0; i < files.length; i++) {
        fd.append('attachments', files[i])
    }
    let dataObj = obj
    console.log("dataObj", obj)
    fd.append("data", JSON.stringify(obj));
    const basicHeaders = {
        "Accept": "application/json",
        "Content-Type": "multipart/form-data",
        "Authorization": "Bearer " + token,
    };

    const options = {
        headers: basicHeaders
    };
    const url = baseUrl + "/sccom/sendMessage";
    // const data = new FormData();
    // data.append(obj)
    return await axios.post(url, fd, options)
}

export async function listOfMessages(token) {
    let loginUserId = localStorage.getItem('userid')
    const basicHeaders = {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
    };
    const url = baseUrl + "/sccom/getInbox";
    const options = { headers: basicHeaders };
    return await axios.get(url + '?userName=' + loginUserId, options)
}


export async function setSecretPattern(token, patternCode) {
    let loginUserId = localStorage.getItem('userid')
    const basicHeaders = {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
    };
    let obj = {
        "patternCode": patternCode
    }
    const url = baseUrl + "/sccom/setSecretPattern";
    const options = { headers: basicHeaders };
    return await axios.put(url + '?userName=' + loginUserId, obj, options)
}

export async function checkSecretPattern(token, obj) {
    const basicHeaders = {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
    };
    const options = { headers: basicHeaders };
    const url = baseUrl + "/sccom/checkSecretPattern"
    return await axios.post(url, obj, options)
}

export async function generateDecryptSessionKey(token, obj) {
    const basicHeaders = {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
    };
    const options = { headers: basicHeaders };
    const url = baseUrl + "/sccom/generateDecryptSessionKey"
    return await axios.post(url, obj, options)
}

export async function getOtpForRepliedMessage(token, obj) {
    const basicHeaders = {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
    };
    const options = { headers: basicHeaders };
    const url = baseUrl + "/sccom/getOtpForRepliedMessage"
    return await axios.post(url, obj, options)
}

export async function getSecretePattern(token, obj) {
    const basicHeaders = {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
    };
    const options = { headers: basicHeaders };
    const url = baseUrl + "/sccom/requestForSecretePattern"
    return await axios.post(url, obj, options)
}


export async function getSentBox(token) {
    let loginUserId = localStorage.getItem('userid')
    const basicHeaders = {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
    };
    const url = baseUrl + "/sccom/getSentBox";
    const options = { headers: basicHeaders };
    return await axios.get(url + '?userName=' + loginUserId, options)
}


export async function deletePatternMessage(token, obj) {
    const basicHeaders = {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
    };
    const options = { headers: basicHeaders };
    const url = baseUrl + "/sccom/deletePatternMessage"
    return await axios.post(url, obj, options)
}

export async function getPatternByUserName(token) {
    let loginUserId = localStorage.getItem('userid')
    const basicHeaders = {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
    };
    const url = baseUrl + "/sccom/getPatternByUserName";
    const options = { headers: basicHeaders };
    return await axios.get(url + '?userName=' + loginUserId, options)
}


export async function deleteMessage(token, obj) {
    const basicHeaders = {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
    };
    const options = { headers: basicHeaders };
    const url = baseUrl + "/sccom/deleteMessage"
    return await axios.post(url, obj, options)
}


export async function getSecretePatternToReadAsOTP(token, obj) {
    const basicHeaders = {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
    };
    const options = { headers: basicHeaders };
    const url = baseUrl + "/sccom/getSecretPattern"
    return await axios.post(url, obj, options)
}

export async function getPreviousMessages(token, obj) {
    let params = obj
    const basicHeaders = {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
        "responseType": 'blob'
    };
    const url = baseUrl + "/sccom/getPreviousMessages"
    const options = {
        headers: basicHeaders,
        params: params
    };
    return await axios.get(url, options)
}

export async function getDocuments(token, obj) {
    let params = obj;
    const basicHeaders = {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
        "responseType": 'blob'
    };
    const url = baseUrl + "/sccom/getDocuments";
    const options = {
        headers: basicHeaders,
        params: params
    };

    return await axios.get(url, options)
}


export async function getSentMessage(token, obj) {
    let params = obj
    const basicHeaders = {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
        "responseType": 'blob'
    };
    const url = baseUrl + "/sccom/getSentMessage"
    const options = {
        headers: basicHeaders,
        params: params
    };
    return await axios.get(url, options)
}

export async function forwardMessage(token, obj) {
    const basicHeaders = {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
    };
    const options = { headers: basicHeaders };
    const url = baseUrl + "/sccom/forwardMessage"
    return await axios.post(url, obj, options)
}

export async function replyToMessage(token, obj, files) {
    let fd = new FormData();
    for (let i = 0; i < files.length; i++) {
        fd.append('attachments', files[i])
    }
    fd.append("data", JSON.stringify(obj));
    const basicHeaders = {
        "Accept": "application/json",
        "Content-Type": "multipart/form-data",
        "Authorization": "Bearer " + token,
    };
    console.log("obj", obj.cc)
    const options = {
        headers: basicHeaders,
    };
    const url = baseUrl + "/sccom/replyToMessage";
    return await axios.post(url, fd, options)
}



export async function googleredirect(obj) {
    const basicHeaders = {
        "Accept": "application/json",
        "Content-Type": "application/json",
        // "Authorization": "Bearer " + token,
    };
    const options = { headers: basicHeaders };
    const url = baseUrl + "/auth/google"
    return await axios.post(url, obj, options)
}

export async function checkPatternValidity(token) {
    let loginUserId = localStorage.getItem('userid')
    const basicHeaders = {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
    };
    const url = baseUrl + "/sccom/checkPatternValidity";
    const options = { headers: basicHeaders };
    return await axios.get(url + '?userName=' + loginUserId, options)
}


export async function findUsers(token, obj) {
    const basicHeaders = {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
    };
    const options = { headers: basicHeaders };
    const url = baseUrl + "/sccom/findUsers"
    return await axios.post(url, obj, options)
}







