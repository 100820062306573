import "./Main.css";
import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Swal from "sweetalert2";
import DoneIcon from '@mui/icons-material/Done';
import CryptoJS from 'crypto-js';
import { useNavigate } from "react-router-dom";
import { checkSecretPattern } from "../../services/ApiService"


const Main = () => {
    let navigate = useNavigate();
    const cardArray = [

        {
            'cardIndex': '1',
            'cardColor': '#9400D3',
            'cardHash': '21343523467698794234',
            'colorName': 'Violet'
        },
        {
            'cardIndex': '2',
            'cardColor': '#4B0082',
            'cardHash': '21343523467698794234',
            'colorName': 'Indigo'
        }
        ,
        {
            'cardIndex': '3',
            'cardColor': '#0000FF',
            'cardHash': '21343523467698794234',
            'colorName': 'Blue'
        },
        {
            'cardIndex': '4',
            'cardColor': '#00FF00',
            'cardHash': '21343523467698794234',
            'colorName': 'Green'

        },
        {
            'cardIndex': '5',
            'cardColor': '#FFFF00',
            'cardHash': '21343523467698794234',
            'colorName': 'Yellow'
        },
        {
            'cardIndex': '6',
            'cardColor': '#FF7F00',
            'cardHash': '21343523467698794234',
            'colorName': 'Orange'
        },
        {
            'cardIndex': '7',
            'cardColor': '#FF0000',
            'cardHash': '21343523467698794234',
            'colorName': 'Red'
        },
        {
            'cardIndex': '8',
            'cardColor': '#ffa07a',
            'cardHash': '21343523467698794234',
            'colorName': 'Peach'
        },
        {
            'cardIndex': '9',
            'cardColor': '#00ffff',
            'cardHash': '21343523467698794234',
            'colorName': 'Sky'
        }
    ]
    var randomObject = cardArray.sort(() => Math.random() - 0.5);
    let pushCombination = [];
    const cardClick = async (cardIndex, clickindex) => {

        document.getElementById(clickindex).style.display = 'block'
        if (pushCombination.length !== 4) {
            pushCombination.push(cardIndex)
        }
        if (pushCombination.length === 4) {
            let localtoken = localStorage.getItem('token');
            let token = JSON.parse(localtoken).token;
            let finalpattern = '0' + pushCombination[0] + '0' + pushCombination[1] + '0' + pushCombination[2] + '0' + pushCombination[3];
            let loginUserId = localStorage.getItem('userid')
            let obj = {
                userName: loginUserId,
                patternCode: finalpattern
            }
            checkSecretPattern(token, obj).then(resp => {
                console.log("resp", resp);
                if (resp.data.status === 'success') {
                    navigate('/AppBase');
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Try New Combination',
                        confirmButtonText: "OK",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            pushCombination = [];
                            window.location.reload(false);
                        }
                    })
                }
            })
        }
    }


    const box = [];
    for (let data of randomObject) {
        box.push(
            <div>
                <Grid item xs={3} sx={{ marginTop: '2%' }} className="justify-center flex">
                    <div
                        onClick={() => { cardClick(data.cardIndex, randomObject.indexOf(data)) }}
                        className="p-[16%] w-[0px] h-[50px] rounded-[15%] hover:shadow-lg cursor-pointer	drop-shadow-lg"
                        style={{ backgroundColor: data.cardColor }}>
                        <DoneIcon id={randomObject.indexOf(data)} sx={{ display: 'none', marginTop: '27%', marginLeft: '90%', fontSize: 40 }}></DoneIcon>
                    </div>
                </Grid >
                <div>
                    <label className="flex justify-center mt-5 text-xl font-light">{data.colorName}</label>
                </div>
            </div>
        )
    }
    return (
        <div className="main__container1 flex-1">
            <Box sx={{ flexGrow: 1 }}>
                <h2 style={{ color: 'red' }}>
                    *********** Enter pattern to send message ***********
                </h2>
                <div className="grid grid-cols-3 content-center">
                    {box}
                </div>
            </Box>

        </div>
    )
}


export default Main;