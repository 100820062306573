import React, { useRef } from "react";
import ReactDOMServer from 'react-dom/server'; // Import ReactDOMServer
import QRCode from 'react-qr-code';
import html2canvas from 'html2canvas';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useNavigate, useLocation } from "react-router-dom";

const QRCodeComponent = () => {
    const containerRef = useRef(null);
    const back = '#FFFFFF'; // Replace with your desired background color
    const fore = '#000000'; // Replace with your desired foreground color
    const size = 450; // Replace with your desired QR code size
    const location = useLocation();
    let navigate = useNavigate();

    const close = () => {
        navigate('/Inbox');
    }

    const handleQrDownload = () => {
        console.log("@@@@")
        const container = containerRef.current;

        if (container) {
            // Make the hidden content visible for the screenshot
            const hiddenContent = container.querySelectorAll('.hidden-content');
            hiddenContent.forEach(element => {
                element.style.visibility = 'visible';
            });
            html2canvas(container, { scale: 2 }).then(canvas => {
                const context = canvas.getContext('2d');

                // Convert canvas to data URL
                const dataUrl = canvas.toDataURL('image/png');

                // Reset the visibility of hidden content
                hiddenContent.forEach(element => {
                    element.style.visibility = 'hidden';
                });

                // Create a temporary link element
                const link = document.createElement('a');
                link.href = dataUrl;
                link.download = 'qrcode' + '.png';

                // Simulate a click to trigger the download
                link.click();
            });
        }
    };

    return (
        <div className="flex flex-col">
            <div className=' ml-[35%] pt-[8%]' ref={containerRef}>
                <QRCode
                    title="QR"
                    value={`${location.state.uuid} ${location.state.repliedUuid} ${location.state.messageType}`}
                    bgColor={back}
                    fgColor={fore}
                    size={size === '' ? 0 : size}
                    onClick={handleQrDownload}
                    style={{ cursor: 'pointer', justifyContent: "center", display: "flex" }}
                />
                <span className="hidden-content p-5" style={{ visibility: 'hidden' }}>
                    <sapn className=""> Subject : {location.state.subject}</sapn>
                    <div className='pl-[2%] text-red-500'>
                        Scan this on Resiliunt
                    </div>
                </span>
            </div>
            <div className="pt-[2%] text-xl  ml-[40%]">
                <button title='QR download' onClick={handleQrDownload} className="text-blue-500">
                    Download QR
                </button>
                <button title="close" onClick={close} className="pl-[5%] text-red-500" >
                    Close
                </button>
            </div>

        </div >
    )
};

export default QRCodeComponent;